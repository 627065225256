import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TSearchParams } from '../type/search-params.type';
import { TSearchResponse } from '../type/search-response.type';

export type TSearchActionOption<T extends IBaseCrudItem = IBaseCrudItem> = {
  groupId?: TDataGroupId;
  functionGroupId?: TDataGroupId;
  force?: boolean;
  callback?: TActionCallback<TSearchResponse<T>>;
  reset?: boolean;
};

export const searchAction = action(
  'searchAction',
  <T extends IBaseCrudItem = any>(tableId: TTableId | undefined, params?: TSearchParams, options?: TSearchActionOption<T>) => {
    return {
      ...options,
      tableId,
      params,
      groupId: options?.groupId || DEFAULT_GROUP,
      functionGroupId: options?.functionGroupId || DEFAULT_GROUP
    };
  }
);
