import { TConfigSearchActionOption } from '../action/config-search.action';
import { TFunctionGroupId } from '../store/crud-data.type';
import { TAddNewFunction } from '../type/add-new-function.type';
import { TAddNewMultiFunction } from '../type/add-new-multi-function.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TDeleteItemFunction } from '../type/delete-item-function.type';
import { TDeleteMultiItemsFunction } from '../type/delete-multi-items-function.type';
import { TFetchItemFunction } from '../type/fetch-detail-function.type';
import { TSearchFunction } from '../type/search-function.type';
import { TUpdateItemFunction } from '../type/update-item-function.type';

export type TBaseCrudSearchConfig<T extends IBaseCrudItem = IBaseCrudItem, P = unknown> = {
  default: {
    search?: TSearchFunction<P, T>;
    option?: TConfigSearchActionOption<P>;
  };
  [functionGroupId: TFunctionGroupId]: {
    search?: TSearchFunction<P, T>;
    option?: TConfigSearchActionOption<P>;
  };
};

export type TBaseCrudFetchFunctionConfig<T extends IBaseCrudItem = IBaseCrudItem> = {
  default: TFetchItemFunction<T> | undefined;
  [functionGroupId: TFunctionGroupId]: TFetchItemFunction<T> | undefined;
};

export type TBaseCrudAddNewFunctionConfig<T extends IBaseCrudItem = IBaseCrudItem> = {
  default: TAddNewFunction<T> | undefined;
  [functionGroupId: TFunctionGroupId]: TAddNewFunction<T> | undefined;
};

export type TBaseCrudAddNewMultiFunctionConfig<T extends IBaseCrudItem = IBaseCrudItem> = {
  default: TAddNewMultiFunction<T> | undefined;
  [functionGroupId: TFunctionGroupId]: TAddNewMultiFunction<T> | undefined;
};

export type TBaseCrudUpdateFunctionConfig<T extends IBaseCrudItem = IBaseCrudItem> = {
  default: TUpdateItemFunction<T> | undefined;
  [functionGroupId: TFunctionGroupId]: TUpdateItemFunction<T> | undefined;
};

export type TBaseCrudDeleteFunctionConfig = {
  default: TDeleteItemFunction | undefined;
  [functionGroupId: TFunctionGroupId]: TDeleteItemFunction | undefined;
};

export type TBaseCrudDeleteMultiFunctionConfig = {
  default: TDeleteMultiItemsFunction | undefined;
  [functionGroupId: TFunctionGroupId]: TDeleteMultiItemsFunction | undefined;
};
