import { orchestrator } from 'satcheljs';

import { refreshAction } from '../action/refresh.action';
import { searchAction } from '../action/search.action';

orchestrator(refreshAction, actionMessage => {
  const { tableId, groupId, functionGroupId, callback } = actionMessage;

  if (!tableId) return callback?.error?.('tableId missing!');
  searchAction(tableId, undefined, {
    groupId,
    functionGroupId,
    callback,
    force: true
  });
});
