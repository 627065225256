import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export type TFetchItemActionOption<T extends IBaseCrudItem = IBaseCrudItem> = {
  force?: boolean;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<T>;
};

export const fetchItemAction = action(
  'fetchItemAction',
  <T extends IBaseCrudItem = any>(tableId: string | undefined, itemId: IBaseCrudItem['id'], options?: TFetchItemActionOption<T>) => ({
    tableId,
    itemId,
    force: options?.force,
    functionGroupId: options?.functionGroupId || DEFAULT_GROUP,
    callback: options?.callback
  })
);
