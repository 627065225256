import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';

export const clearAction = mutatorAction('clearAction', (tableId: string | undefined) => {
  if (!tableId) return;

  getCrudDataStore().listDataMap.set(tableId, new ObservableMap());
  getCrudDataStore().singleItemMap.set(tableId, new ObservableMap());
});
