import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';
import { TSearchParams } from '../type/search-params.type';

export const setSearchParamsAction = mutatorAction(
  'updateSearchParamsAction',
  (tableId: TTableId | undefined, params: TSearchParams, options?: { replace?: boolean; groupId?: TDataGroupId }) => {
    if (!tableId) return;

    const { replace = false, groupId = DEFAULT_GROUP } = options || {};

    let _tableSearchParamsMap = getCrudDataStore().searchParamsMap.get(tableId);

    if (!_tableSearchParamsMap) {
      _tableSearchParamsMap = new ObservableMap();
      getCrudDataStore().searchParamsMap.set(tableId, _tableSearchParamsMap);
    }

    if (replace) {
      _tableSearchParamsMap.set(groupId, params);
    } else {
      const prevSearchParams = _tableSearchParamsMap.get(groupId);
      _tableSearchParamsMap.set(groupId, { ...prevSearchParams, ...params });
    }

    if (groupId === DEFAULT_GROUP) {
      getCrudDataStore().defaultSearchParamsMap.set(tableId, params);
    }
  }
);
