import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TSearchFunction } from '../type/search-function.type';

export const setSearchFunctionAction = mutatorAction(
  'setSearchFunctionAction',
  (tableId: TTableId | undefined, searchFunction: TSearchFunction, functionGroupId: TFunctionGroupId = DEFAULT_GROUP) => {
    if (!tableId) return;

    let _tableSearchFunctionMap = getCrudDataStore().searchFunctionMap.get(tableId);

    if (!_tableSearchFunctionMap) {
      _tableSearchFunctionMap = new ObservableMap();
      getCrudDataStore().searchFunctionMap.set(tableId, _tableSearchFunctionMap);
    }

    _tableSearchFunctionMap.set(functionGroupId, searchFunction);
  }
);
