import { orchestrator } from 'satcheljs';

import { deleteMultiItemsAction } from '../action/delete-multi-items.action';
import { setItemListDataAction } from '../mutator-action/set-item-list-data.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { deleteMultiFunctionSelector } from '../selector/delete-multi-function.selector';

orchestrator(deleteMultiItemsAction, async actionMessage => {
  const { tableId, itemIds, groupId, functionGroupId, callback } = actionMessage;

  try {
    if (!tableId) throw new Error('tableId missing!');

    const deleteMultiFunction = deleteMultiFunctionSelector(tableId, functionGroupId);

    if (!deleteMultiFunction) throw new Error('deleteFunction not found!');

    const _response = await deleteMultiFunction?.(itemIds);

    _response?.forEach((id?: string | number) => {
      if (id == null) return;
      setItemListDataAction(tableId, id, undefined, groupId);
      setSingleItemAction(tableId, id, undefined);
    });

    callback?.success?.(_response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
