import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';

export const seekingValueNameSelector = (tableId: TTableId | undefined, groupId: TDataGroupId = DEFAULT_GROUP) => {
  return (
    (tableId &&
      (getCrudDataStore().paginationMetaMap.get(tableId)?.get(groupId)?.seekingValueName ||
        getCrudDataStore().paginationMetaMap.get(tableId)?.get(DEFAULT_GROUP)?.seekingValueName)) ||
    undefined
  );
};
