import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TSearchResponse } from '../type/search-response.type';

export type TRefreshActionOption = {
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<TSearchResponse>;
};

export const refreshAction = action('refreshAction', (tableId: TTableId | undefined, options?: TRefreshActionOption) => {
  return {
    tableId,
    groupId: options?.groupId ?? DEFAULT_GROUP,
    functionGroupId: options?.functionGroupId ?? DEFAULT_GROUP,
    callback: options?.callback
  };
});
