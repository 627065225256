import { action } from 'satcheljs';

import { DEFAULT_GROUP, TTableId } from '../store/crud-data.type';

export const removeDataAction = action(
  'addDataAction',
  <T extends { id?: string | number }>(tableId: TTableId | undefined, data: Array<T>, groupId = DEFAULT_GROUP) => {
    return {
      tableId,
      data,
      groupId
    };
  }
);
