import { IObservableArray, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';

export const allItemsSelector = computedFn(
  <T = unknown>(tableId: TTableId | undefined, groupId: TDataGroupId | undefined): IObservableArray<T> => {
    if (!tableId) return observable.array([]);

    return (
      getCrudDataStore()
        .listDataMap.get(tableId)
        ?.get(groupId ?? DEFAULT_GROUP) || observable.array([])
    );
  }
);
