import { computedFn } from 'mobx-utils';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';
import { allItemsSelector } from './all-items.selector';

export const totalItemsSelector = computedFn((tableId: TTableId | undefined, groupId: TDataGroupId | undefined) => {
  return (
    (tableId &&
      getCrudDataStore()
        .paginationMetaMap.get(tableId)
        ?.get(groupId ?? DEFAULT_GROUP)?.totalItems) ||
    allItemsSelector(tableId, groupId).length ||
    0
  );
});
