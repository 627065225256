import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId, TTableId } from '../store/crud-data.type';

export type TChangePageSizeOptions = {
  size: number;
  groupId?: TDataGroupId;
  searchFunctionGroupId?: TFunctionGroupId;
  callback?: TActionCallback;
};

export const changePageSizeAction = action('changePageSizeAction', (tableId: TTableId | undefined, options: TChangePageSizeOptions) => {
  return {
    ...options,
    tableId,
    groupId: options.groupId ?? DEFAULT_GROUP,
    searchFunctionGroupId: options.searchFunctionGroupId ?? DEFAULT_GROUP
  };
});
