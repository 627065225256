import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TAddNewMultiFunction } from '../type/add-new-multi-function.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export const addNewMultiFunctionSelector = <T extends IBaseCrudItem>(
  tableId: TTableId | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TAddNewMultiFunction<T> | undefined => {
  return (
    (tableId &&
      (getCrudDataStore().addNewMultiFunctionMap.get(tableId)?.get(functionGroupId) ||
        getCrudDataStore().addNewMultiFunctionMap.get(tableId)?.get(DEFAULT_GROUP))) ||
    undefined
  );
};
