import { v4 as uuid } from 'uuid';

import { Tail } from '@totopkg/shared-util-common';

import { addNewItemAction } from '../action/add-new-item.action';
import { addNewMultiItemsAction } from '../action/add-new-multi-items.action';
import { changePageSizeAction } from '../action/change-page-size.action';
import { configSearchAction, TConfigSearchActionOption } from '../action/config-search.action';
import { deleteItemAction } from '../action/delete-item.action';
import { deleteMultiItemsAction } from '../action/delete-multi-items.action';
import { fetchItemAction } from '../action/fetch-item.action';
import { goToPageAction, TGoToPageActionOption } from '../action/go-to-page.action';
import { nextPageAction } from '../action/next-page.action';
import { prevPageAction } from '../action/prev-page.action';
import { refreshAction, TRefreshActionOption } from '../action/refresh.action';
import { removeDataAction } from '../action/remove-data.action';
import { searchAction, TSearchActionOption } from '../action/search.action';
import { TUpdateItemActionOption, updateItemAction } from '../action/update-item.action';
import { addListDataAction } from '../mutator-action/add-list-data.action';
import { clearAction } from '../mutator-action/clear.action';
import { setAddNewFunctionAction } from '../mutator-action/set-add-new-function.action';
import { setAddNewMultiFunctionAction } from '../mutator-action/set-add-new-multi-function.action';
import { setDeleteFunctionAction } from '../mutator-action/set-delete-function.action';
import { setDeleteMultiFunctionAction } from '../mutator-action/set-delete-multi-function.action';
import { setFetchItemFunction } from '../mutator-action/set-fetch-item-function.action';
import { setItemListDataAction } from '../mutator-action/set-item-list-data.action';
import { setListDataAction } from '../mutator-action/set-list-data.action';
import { setPaginationMetaAction } from '../mutator-action/set-pagination-meta.action';
import { setSearchFunctionAction } from '../mutator-action/set-search-function.action';
import { setSearchParamsAction } from '../mutator-action/set-search-params.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { setUpdateFunctionAction } from '../mutator-action/set-update-function.action';
import { addNewFunctionSelector } from '../selector/add-new-function.selector';
import { addNewMultiFunctionSelector } from '../selector/add-new-multi-function.selector';
import { allItemsSelector } from '../selector/all-items.selector';
import { canLoadMoreSelector } from '../selector/can-load-more.selector';
import { canNextPageSelector } from '../selector/can-next-page.selector';
import { currentPageItemsSelector } from '../selector/current-page-items.selector';
import { currentPageSelector } from '../selector/current-page.selector';
import { deleteFunctionSelector } from '../selector/delete-function.selector';
import { fetchItemFunctionSelector } from '../selector/fetch-item-function.selector';
import { pageItemsSelector } from '../selector/page-items.selector';
import { pageSizeSelector } from '../selector/page-size.selector';
import { paginationMetaSelector } from '../selector/pagination-meta.selector';
import { searchFunctionSelector } from '../selector/search-function.selector';
import { searchParamsSelector } from '../selector/search-params.selector';
import { seekingKeyNameSelector } from '../selector/seeking-key-name.selector';
import { seekingValueNameSelector } from '../selector/seeking-value-name.selector';
import { singleItemSelector } from '../selector/single-item.selector';
import { tableLoadingSelector } from '../selector/table-loading.selector';
import { totalItemsSelector } from '../selector/total-items.selector';
import { totalPagesSelector } from '../selector/total-pages.selector';
import { updateFunctionSelector } from '../selector/update-function.selector';
import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId } from '../store/crud-data.type';
import { TAddNewFunction } from '../type/add-new-function.type';
import { TAddNewMultiFunction } from '../type/add-new-multi-function.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TDeleteItemFunction } from '../type/delete-item-function.type';
import { TDeleteMultiItemsFunction } from '../type/delete-multi-items-function.type';
import { TFetchItemFunction } from '../type/fetch-detail-function.type';
import { IPaginationMeta } from '../type/pagination-meta.type';
import { TSearchFunction } from '../type/search-function.type';
import { TSearchParams } from '../type/search-params.type';
import { TUpdateItemFunction } from '../type/update-item-function.type';
import {
  TBaseCrudAddNewFunctionConfig,
  TBaseCrudAddNewMultiFunctionConfig,
  TBaseCrudDeleteFunctionConfig,
  TBaseCrudDeleteMultiFunctionConfig,
  TBaseCrudFetchFunctionConfig,
  TBaseCrudSearchConfig,
  TBaseCrudUpdateFunctionConfig
} from './base-crud-config.type';

export abstract class BaseCrudData<T extends IBaseCrudItem = IBaseCrudItem, P = unknown> {
  protected tableId: string | undefined;

  protected constructor() {
    if (!this.tableId) {
      this.tableId = `${this.constructor.name.toLocaleLowerCase()}-${uuid()}`;
    }

    this.builder();

    // ** bind this context
    this.addListData = this.addListData.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.addNewMultiItems = this.addNewMultiItems.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.config = this.config.bind(this);
    this.configSearch = this.configSearch.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.deleteMultiItems = this.deleteMultiItems.bind(this);
    this.fetchItem = this.fetchItem.bind(this);
    this.getAddNewFunction = this.getAddNewFunction.bind(this);
    this.getAddNewMultiFunction = this.getAddNewMultiFunction.bind(this);
    this.getAllItems = this.getAllItems.bind(this);
    this.getCanLoadMore = this.getCanLoadMore.bind(this);
    this.getCanNextPage = this.getCanNextPage.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.getCurrentPageItems = this.getCurrentPageItems.bind(this);
    this.getDeleteFunction = this.getDeleteFunction.bind(this);
    this.getDetailFetchFunction = this.getDetailFetchFunction.bind(this);
    this.getDetailItem = this.getDetailItem.bind(this);
    this.getLoading = this.getLoading.bind(this);
    this.getPageItems = this.getPageItems.bind(this);
    this.getPageSize = this.getPageSize.bind(this);
    this.getPaginationMeta = this.getPaginationMeta.bind(this);
    this.getSearchFunction = this.getSearchFunction.bind(this);
    this.getSearchParams = this.getSearchParams.bind(this);
    this.getSeekingKeyName = this.getSeekingKeyName.bind(this);
    this.getSeekingValueName = this.getSeekingValueName.bind(this);
    this.getTotalItems = this.getTotalItems.bind(this);
    this.getTotalPages = this.getTotalPages.bind(this);
    this.getUpdateFunction = this.getUpdateFunction.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.refresh = this.refresh.bind(this);
    this.removeData = this.removeData.bind(this);
    this.search = this.search.bind(this);
    this.setAddNewFunction = this.setAddNewFunction.bind(this);
    this.setAddNewMultiFunction = this.setAddNewMultiFunction.bind(this);
    this.setDeleteFunction = this.setDeleteFunction.bind(this);
    this.setDeleteMultiFunction = this.setDeleteMultiFunction.bind(this);
    this.setFetchItemFunction = this.setFetchItemFunction.bind(this);
    this.setListData = this.setListData.bind(this);
    this.setPaginationMeta = this.setPaginationMeta.bind(this);
    this.setSearchFunction = this.setSearchFunction.bind(this);
    this.setSearchParams = this.setSearchParams.bind(this);
    this.setSingleItem = this.setSingleItem.bind(this);
    this.setUpdateFunction = this.setUpdateFunction.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.setItemListData = this.setItemListData.bind(this);
    this.clear = this.clear.bind(this);
  }

  public addListData(...args: Tail<Parameters<typeof addListDataAction>>) {
    addListDataAction(this.tableId, ...args);
  }

  public addNewItem(...args: Tail<Parameters<typeof addNewItemAction>>) {
    addNewItemAction(this.tableId, ...args);
  }

  public addNewMultiItems(...args: Tail<Parameters<typeof addNewMultiItemsAction>>) {
    addNewMultiItemsAction(this.tableId, ...args);
  }

  public changePageSize(...args: Tail<Parameters<typeof changePageSizeAction>>) {
    changePageSizeAction(this.tableId, ...args);
  }

  public config(config: {
    search?: TBaseCrudSearchConfig<T, P>;
    fetch?: TBaseCrudFetchFunctionConfig<T>;
    addNew?: TBaseCrudAddNewFunctionConfig<T>;
    addNewMulti?: TBaseCrudAddNewMultiFunctionConfig<T>;
    update?: TBaseCrudUpdateFunctionConfig<T>;
    delete?: TBaseCrudDeleteFunctionConfig;
    deleteMulti?: TBaseCrudDeleteMultiFunctionConfig;
  }) {
    if (config.search) {
      Object.keys(config.search).forEach(key => {
        const _config = config.search?.[key];
        _config?.search &&
          this.configSearch(_config.search, {
            ..._config.option,
            groupId: _config.option?.groupId || key
          });
      });
    }

    if (config.fetch) {
      Object.keys(config.fetch).forEach(key => {
        const _function = config.fetch?.[key];
        _function && this.setFetchItemFunction(_function, key);
      });
    }

    if (config.addNew) {
      Object.keys(config.addNew).forEach(key => {
        const _function = config.addNew?.[key];
        _function && this.setAddNewFunction(_function, key);
      });
    }

    if (config.addNewMulti) {
      Object.keys(config.addNewMulti).forEach(key => {
        const _function = config.addNewMulti?.[key];
        _function && this.setAddNewMultiFunction(_function, key);
      });
    }

    if (config.update) {
      Object.keys(config.update).forEach(key => {
        const _function = config.update?.[key];
        _function && this.setUpdateFunction(_function, key);
      });
    }

    if (config.delete) {
      Object.keys(config.delete).forEach(key => {
        const _function = config.delete?.[key];
        _function && this.setDeleteFunction(_function, key);
      });
    }

    if (config.deleteMulti) {
      Object.keys(config.deleteMulti).forEach(key => {
        const _function = config.deleteMulti?.[key];
        _function && this.setDeleteMultiFunction(_function, key);
      });
    }
  }

  public configSearch(searchFunction: TSearchFunction<P, T>, option?: TConfigSearchActionOption) {
    configSearchAction(this.tableId, searchFunction, option);
  }

  public deleteItem(...args: Tail<Parameters<typeof deleteItemAction>>) {
    deleteItemAction(this.tableId, ...args);
  }

  public deleteMultiItems(...args: Tail<Parameters<typeof deleteMultiItemsAction>>) {
    deleteMultiItemsAction(this.tableId, ...args);
  }

  public fetchItem(...args: Tail<Parameters<typeof fetchItemAction>>) {
    fetchItemAction(this.tableId, ...args);
  }

  public getAddNewFunction(functionGroupId?: TFunctionGroupId) {
    return addNewFunctionSelector<T>(this.tableId, functionGroupId);
  }

  public getAddNewMultiFunction(functionGroupId?: TFunctionGroupId) {
    return addNewMultiFunctionSelector<T>(this.tableId, functionGroupId);
  }

  public getAllItems(groupId?: TDataGroupId) {
    return allItemsSelector<T>(this.tableId, groupId ?? DEFAULT_GROUP);
  }

  public getCanLoadMore(groupId?: TDataGroupId) {
    return canLoadMoreSelector(this.tableId, groupId ?? DEFAULT_GROUP);
  }

  public getCanNextPage(groupId?: TDataGroupId) {
    return canNextPageSelector(this.tableId, groupId ?? DEFAULT_GROUP);
  }

  public getCurrentPage(groupId?: TDataGroupId) {
    return currentPageSelector(this.tableId, groupId);
  }

  public getCurrentPageItems(groupId?: TDataGroupId) {
    return currentPageItemsSelector<T>(this.tableId, groupId);
  }

  public getDeleteFunction(functionGroupId?: TFunctionGroupId) {
    return deleteFunctionSelector(this.tableId, functionGroupId);
  }

  public getDetailFetchFunction(functionGroupId?: TFunctionGroupId) {
    return fetchItemFunctionSelector(this.tableId, functionGroupId);
  }

  public getDetailItem(itemId: IBaseCrudItem['id']) {
    return singleItemSelector<T>(this.tableId, itemId);
  }

  public getLoading(groupId?: TDataGroupId) {
    return tableLoadingSelector(this.tableId, groupId);
  }

  public getPageItems(page: number, groupId?: TDataGroupId) {
    return pageItemsSelector<T>(this.tableId, page, groupId ?? DEFAULT_GROUP);
  }

  public getPageSize(groupId?: TDataGroupId) {
    return pageSizeSelector(this.tableId, groupId);
  }

  public getPaginationMeta(groupId?: TDataGroupId) {
    return paginationMetaSelector(this.tableId, groupId);
  }

  public getSearchFunction(functionGroupId?: TFunctionGroupId) {
    return searchFunctionSelector<P, T>(this.tableId, functionGroupId);
  }

  public getSearchParams(groupId?: TDataGroupId) {
    return searchParamsSelector<P>(this.tableId, groupId);
  }

  public getSeekingKeyName(groupId?: TDataGroupId) {
    return seekingKeyNameSelector(this.tableId, groupId);
  }

  public getSeekingValueName(groupId?: TDataGroupId) {
    return seekingValueNameSelector(this.tableId, groupId);
  }

  public getTotalItems(groupId?: TDataGroupId) {
    return totalItemsSelector(this.tableId, groupId ?? DEFAULT_GROUP);
  }

  public getTotalPages(groupId?: TDataGroupId) {
    return totalPagesSelector(this.tableId, groupId ?? DEFAULT_GROUP);
  }

  public getUpdateFunction(functionGroupId?: TFunctionGroupId) {
    return updateFunctionSelector<T>(this.tableId, functionGroupId);
  }

  public goToPage(page: number, options?: TGoToPageActionOption<T>) {
    goToPageAction(this.tableId, page, options);
  }

  public nextPage(options?: TGoToPageActionOption<T>) {
    nextPageAction(this.tableId, options);
  }

  public prevPage(options?: TGoToPageActionOption<T>) {
    prevPageAction(this.tableId, options);
  }

  public refresh(options?: TRefreshActionOption) {
    refreshAction(this.tableId, options);
  }

  public removeData(data: Array<T>, groupId?: TDataGroupId) {
    removeDataAction(this.tableId, data, groupId);
  }

  public search(params?: TSearchParams<P>, options?: TSearchActionOption<T>) {
    searchAction(this.tableId, params, options);
  }

  public setAddNewFunction(addNewFunction: TAddNewFunction<T>, functionGroupId?: TFunctionGroupId) {
    setAddNewFunctionAction(this.tableId, addNewFunction, functionGroupId);
  }

  public setAddNewMultiFunction(addNewMultiFunction: TAddNewMultiFunction<T>, functionGroupId?: TFunctionGroupId) {
    setAddNewMultiFunctionAction(this.tableId, addNewMultiFunction, functionGroupId);
  }

  public setDeleteFunction(deleteFunction: TDeleteItemFunction, functionGroupId?: TFunctionGroupId) {
    setDeleteFunctionAction(this.tableId, deleteFunction, functionGroupId);
  }

  public setDeleteMultiFunction(deleteFunction: TDeleteMultiItemsFunction, functionGroupId?: TFunctionGroupId) {
    setDeleteMultiFunctionAction(this.tableId, deleteFunction, functionGroupId);
  }

  public setFetchItemFunction(fetchFunction: TFetchItemFunction<T>, functionGroupId?: TFunctionGroupId) {
    setFetchItemFunction(this.tableId, fetchFunction, functionGroupId);
  }

  public setListData(data: Array<T>, groupId?: TDataGroupId) {
    setListDataAction(this.tableId, data, groupId);
  }

  public setPaginationMeta(meta: IPaginationMeta<T>, ...args: Tail<Parameters<typeof setPaginationMetaAction>>) {
    setPaginationMetaAction(this.tableId, ...args);
  }

  public setSearchFunction(searchFunction: TSearchFunction<P, T>, functionGroupId?: TFunctionGroupId) {
    setSearchFunctionAction(this.tableId, searchFunction, functionGroupId);
  }

  public setSearchParams(params: TSearchParams<P>, ...args: Tail<Tail<Parameters<typeof setSearchParamsAction>>>) {
    setSearchParamsAction(this.tableId, params, ...args);
  }

  public setSingleItem(...args: Tail<Parameters<typeof setSingleItemAction>>) {
    setSingleItemAction(this.tableId, ...args);
  }

  public setItemListData(...args: Tail<Parameters<typeof setItemListDataAction>>) {
    setItemListDataAction(this.tableId, ...args);
  }

  public setUpdateFunction(updateFunction: TUpdateItemFunction<T>, functionGroupId?: TFunctionGroupId) {
    setUpdateFunctionAction(this.tableId, updateFunction, functionGroupId);
  }

  public updateItem(itemId: string | number | undefined, data: T | undefined, options?: TUpdateItemActionOption<T>) {
    updateItemAction(this.tableId, itemId, data, options);
  }

  public clear() {
    clearAction(this.tableId);
  }

  /**
   * Must do least one below task
   * @task config
   * @task configSearch
   */
  protected abstract builder(): void;
}
