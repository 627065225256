import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TDeleteItemFunction } from '../type/delete-item-function.type';

export const deleteFunctionSelector = (
  tableId: TTableId | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TDeleteItemFunction | undefined => {
  return (
    (tableId &&
      (getCrudDataStore().deleteFunctionMap.get(tableId)?.get(functionGroupId) ||
        getCrudDataStore().deleteFunctionMap.get(tableId)?.get(DEFAULT_GROUP))) ||
    undefined
  );
};
