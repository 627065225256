import { IObservableArray, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';
import { pageSizeSelector } from './page-size.selector';

export const pageItemsSelector = computedFn(
  <T = unknown>(tableId: TTableId | undefined, page: number, groupId: TDataGroupId | undefined): IObservableArray<T> => {
    if (!tableId) return observable.array([]);

    const pageSize = pageSizeSelector(tableId, groupId);

    return !pageSize
      ? ((getCrudDataStore()
          .listDataMap.get(tableId)
          ?.get(groupId ?? DEFAULT_GROUP) || []) as IObservableArray<T>)
      : ((getCrudDataStore()
          .listDataMap.get(tableId)
          ?.get(groupId ?? DEFAULT_GROUP)
          ?.slice(Math.max(0, page - 1) * pageSize, page * pageSize) || []) as IObservableArray<T>);
  }
);
