import { sum } from 'lodash';
import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { goToPageAction } from '../action/go-to-page.action';
import { addListDataAction } from '../mutator-action/add-list-data.action';
import { setPaginationMetaAction } from '../mutator-action/set-pagination-meta.action';
import { setSearchParamsAction } from '../mutator-action/set-search-params.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { pageItemsSelector } from '../selector/page-items.selector';
import { searchFunctionSelector } from '../selector/search-function.selector';
import { searchParamsSelector } from '../selector/search-params.selector';
import { seekingKeyNameSelector } from '../selector/seeking-key-name.selector';
import { seekingValueNameSelector } from '../selector/seeking-value-name.selector';
import { TSearchParams } from '../type/search-params.type';
import { getTableLoadingId } from '../util/get-table-loading-id.util';

orchestrator(goToPageAction, async actionMessage => {
  const { tableId, page, callback, groupId, searchFunctionGroupId } = actionMessage;

  handler: try {
    if (!tableId) throw new Error('tableId missing!');

    if (LoadingStore.localLoadingSelector(getTableLoadingId(tableId, groupId))) break handler;

    const searchParams = searchParamsSelector(tableId, groupId);

    const pageItems = pageItemsSelector(tableId, page, groupId);

    if ((pageItems?.length || 0) >= (searchParams?.size || 0)) {
      setPaginationMetaAction(
        tableId,
        {
          currentPage: page
        },
        {
          groupId
        }
      );
      break handler;
    } else {
      const seekingKeyName = seekingKeyNameSelector(tableId, groupId);
      const seekingValueName = seekingValueNameSelector(tableId, groupId);

      const optimizeSizeSearchParams = {
        ...searchParams,
        ...(seekingKeyName
          ? {
              [seekingKeyName]: page === 1 ? undefined : (searchParams as any)?.[seekingKeyName]
            }
          : undefined),
        ...(!seekingKeyName
          ? {
              page
            }
          : {
              page: undefined
            })
      } as TSearchParams;

      const searchFunction = searchFunctionSelector(tableId, searchFunctionGroupId);

      if (!searchFunction) throw new Error('searchFunction not found!');

      LoadingStore.updateLocalLoadingAction(getTableLoadingId(tableId, groupId), true);

      const _response = await searchFunction(optimizeSizeSearchParams);

      setPaginationMetaAction(
        tableId,
        {
          canLoadMore: Boolean(_response.remain ?? (_response.data?.length ?? 0) >= (searchParams?.size ?? 0)),
          currentPage: page,
          totalItems: (_response?.remain || 0) > 0 ? sum([_response.data?.length, _response?.remain]) : undefined
        },
        {
          groupId
        }
      );

      addListDataAction(tableId, _response?.data || [], {
        groupId
      });

      if (seekingKeyName) {
        setSearchParamsAction(
          tableId,
          {
            [seekingKeyName]:
              (_response?.data?.[_response.data.length - 1] as any)?.[seekingValueName || seekingKeyName] ??
              (_response as any)?.[seekingValueName || seekingKeyName]
          },
          {
            groupId
          }
        );
      }

      // ** update detail items
      _response?.data?.forEach((item: any) => {
        setSingleItemAction(tableId, item.id, item);
      });

      LoadingStore.updateLocalLoadingAction(getTableLoadingId(tableId, groupId), false);

      callback?.success?.(_response);
    }
  } catch (error) {
    LoadingStore.updateLocalLoadingAction(getTableLoadingId(tableId, groupId), false);

    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
