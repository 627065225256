import { orchestrator } from 'satcheljs';

import { deleteItemAction } from '../action/delete-item.action';
import { setItemListDataAction } from '../mutator-action/set-item-list-data.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { deleteFunctionSelector } from '../selector/delete-function.selector';

orchestrator(deleteItemAction, async actionMessage => {
  const { tableId, itemId, groupId, functionGroupId, callback } = actionMessage;

  try {
    if (!tableId) throw new Error('tableId missing!');
    if (!itemId) throw new Error('itemId missing!');

    const deleteFunction = deleteFunctionSelector(tableId, functionGroupId);

    if (!deleteFunction) throw new Error('deleteFunction not found!');

    const _response = await deleteFunction?.(itemId);
    setItemListDataAction(tableId, itemId, undefined, groupId);
    setSingleItemAction(tableId, itemId, undefined);
    callback?.success?.(_response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
