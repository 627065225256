import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';
import { TSearchParams } from '../type/search-params.type';
import { defaultSearchParamsSelector } from './default-search-params.selector';

export const searchParamsSelector = <T>(
  tableId: TTableId | undefined,
  groupId: TDataGroupId = DEFAULT_GROUP
): TSearchParams<T> | undefined => {
  return (
    (tableId &&
      ((getCrudDataStore().searchParamsMap.get(tableId)?.get(groupId) || defaultSearchParamsSelector(tableId)) as TSearchParams<T>)) ||
    undefined
  );
};
