import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TUpdateItemFunction } from '../type/update-item-function.type';

export const setUpdateFunctionAction = mutatorAction(
  'setUpdateFunctionAction',
  <T extends IBaseCrudItem = any>(
    tableId: string | undefined,
    updateFunction: TUpdateItemFunction<T>,
    functionGroupId: TFunctionGroupId = DEFAULT_GROUP
  ) => {
    if (!tableId) return;

    let _tableUpdateFunctionMap = getCrudDataStore().updateFunctionMap.get(tableId);

    if (!_tableUpdateFunctionMap) {
      _tableUpdateFunctionMap = new ObservableMap<string, TUpdateItemFunction>();
      getCrudDataStore().updateFunctionMap.set(tableId, _tableUpdateFunctionMap);
    }

    _tableUpdateFunctionMap?.set(functionGroupId, updateFunction);
  }
);
