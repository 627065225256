import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TSearchResponse } from '../type/search-response.type';

export type TGoToPageActionOption<T extends IBaseCrudItem = IBaseCrudItem> = {
  groupId?: TDataGroupId;
  searchFunctionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<TSearchResponse<T>>;
};

export const goToPageAction = action(
  'goToPageAction',
  <T extends IBaseCrudItem = any>(tableId: TTableId | undefined, page: number, options?: TGoToPageActionOption<T>) => {
    return {
      tableId,
      page,
      callback: options?.callback,
      groupId: options?.groupId || DEFAULT_GROUP,
      searchFunctionGroupId: options?.searchFunctionGroupId || DEFAULT_GROUP
    };
  }
);
