import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export type TAddNewItemActionOption<T extends IBaseCrudItem = IBaseCrudItem> = {
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<T>;
};

export const addNewItemAction = action(
  'addNewItemAction',
  <T extends IBaseCrudItem = any>(tableId: string | undefined, data: T | undefined, options?: TAddNewItemActionOption<T>) => {
    return {
      tableId,
      data,
      groupId: options?.groupId ?? DEFAULT_GROUP,
      functionGroupId: options?.functionGroupId ?? DEFAULT_GROUP,
      callback: options?.callback
    };
  }
);
