import { computedFn } from 'mobx-utils';

import { TDataGroupId, TTableId } from '../store/crud-data.type';
import { canLoadMoreSelector } from './can-load-more.selector';
import { currentPageSelector } from './current-page.selector';
import { totalPagesSelector } from './total-pages.selector';

export const canNextPageSelector = computedFn((tableId: TTableId | undefined, groupId: TDataGroupId | undefined) => {
  const canLoadMore = canLoadMoreSelector(tableId, groupId);
  const currentPage = currentPageSelector(tableId, groupId);
  const totalPages = totalPagesSelector(tableId, groupId);

  return canLoadMore || currentPage < totalPages;
});
