import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';

export const seekingKeyNameSelector = (tableId: TTableId | undefined, groupId: TDataGroupId = DEFAULT_GROUP) => {
  return (
    (tableId &&
      (getCrudDataStore().paginationMetaMap.get(tableId)?.get(groupId)?.seekingKeyName ||
        getCrudDataStore().paginationMetaMap.get(tableId)?.get(DEFAULT_GROUP)?.seekingKeyName)) ||
    undefined
  );
};
