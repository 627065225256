import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TDeleteMultiItemsFunction } from '../type/delete-multi-items-function.type';

export const deleteMultiFunctionSelector = (
  tableId: TTableId | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TDeleteMultiItemsFunction | undefined => {
  return (
    (tableId &&
      (getCrudDataStore().deleteMultiFunctionMap.get(tableId)?.get(functionGroupId) ||
        getCrudDataStore().deleteMultiFunctionMap.get(tableId)?.get(DEFAULT_GROUP))) ||
    undefined
  );
};
