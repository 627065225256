import { action } from 'satcheljs';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TSearchFunction } from '../type/search-function.type';
import { TSearchParams } from '../type/search-params.type';

export type TConfigSearchActionOption<P = any> = {
  defaultSearchParams?: TSearchParams<P>;
  seekingKeyName?: string;
  seekingValueName?: string;
  replaceExists?: boolean;
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
};

export const configSearchAction = action(
  'configSearchAction',
  (tableId: TTableId | undefined, search: TSearchFunction, options: TConfigSearchActionOption = {}) => {
    return {
      tableId,
      search,
      defaultSearchParams: options.defaultSearchParams,
      replaceExists: options.replaceExists,
      seekingKeyName: options.seekingKeyName,
      seekingValueName: options.seekingValueName,
      groupId: options.groupId || DEFAULT_GROUP,
      functionGroupId: options.functionGroupId || DEFAULT_GROUP
    };
  }
);
