import { orchestrator } from 'satcheljs';

import { goToPageAction } from '../action/go-to-page.action';
import { nextPageAction } from '../action/next-page.action';
import { canNextPageSelector } from '../selector/can-next-page.selector';
import { currentPageSelector } from '../selector/current-page.selector';

orchestrator(nextPageAction, actionMessage => {
  const { tableId, groupId, searchFunctionGroupId, callback } = actionMessage;
  if (!tableId) return callback?.error?.('tableId missing!');

  const canNextPage = canNextPageSelector(tableId, groupId);
  if (!canNextPage) return callback?.error?.('No more data to load!');

  const currentPage = currentPageSelector(tableId, groupId);
  goToPageAction(tableId, currentPage + 1, {
    groupId,
    callback,
    searchFunctionGroupId: searchFunctionGroupId
  });
});
