import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export type TDeleteItemActionOption = {
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<IBaseCrudItem['id']>;
};

export const deleteItemAction = action(
  'deleteItemAction',
  (tableId: string | undefined, itemId: string | undefined, options?: TDeleteItemActionOption) => {
    return {
      tableId,
      itemId,
      groupId: options?.groupId ?? DEFAULT_GROUP,
      functionGroupId: options?.functionGroupId ?? DEFAULT_GROUP,
      callback: options?.callback
    };
  }
);
