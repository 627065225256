import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TUpdateItemFunction } from '../type/update-item-function.type';

export const updateFunctionSelector = <T extends IBaseCrudItem = IBaseCrudItem>(
  tableId: TTableId | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TUpdateItemFunction<T> | undefined => {
  return (
    (tableId &&
      ((getCrudDataStore().updateFunctionMap.get(tableId)?.get(functionGroupId) ||
        getCrudDataStore().updateFunctionMap.get(tableId)?.get(DEFAULT_GROUP)) as TUpdateItemFunction<T> | undefined)) ||
    undefined
  );
};
