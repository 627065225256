import { orchestrator } from 'satcheljs';

import { removeDataAction } from '../action/remove-data.action';
import { setListDataAction } from '../mutator-action/set-list-data.action';
import { allItemsSelector } from '../selector/all-items.selector';

orchestrator(removeDataAction, actionMessage => {
  const { tableId, data, groupId } = actionMessage;
  if (!tableId) return;

  const allData = allItemsSelector<{ id?: string }>(tableId, groupId);
  const dataAfterRemoved = allData.filter((item: any) => data.every(removeItem => removeItem.id !== item.id));

  setListDataAction(tableId, dataAfterRemoved, groupId);
});
