import { orchestrator } from 'satcheljs';

import { goToPageAction } from '../action/go-to-page.action';
import { prevPageAction } from '../action/prev-page.action';
import { currentPageSelector } from '../selector/current-page.selector';

orchestrator(prevPageAction, actionMessage => {
  const { tableId, groupId, searchFunctionGroupId, callback } = actionMessage;
  if (!tableId) return callback?.error?.('tableId missing!');

  const currentPage = currentPageSelector(tableId, groupId);
  if (currentPage <= 1) return callback?.error?.('No more previous page!');

  goToPageAction(tableId, currentPage - 1, {
    groupId,
    searchFunctionGroupId: searchFunctionGroupId,
    callback
  });
});
