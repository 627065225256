import { observable, ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';

export const setListDataAction = mutatorAction(
  'setListDataAction',
  <T>(tableId: TTableId | undefined, data: Array<T>, groupId: TDataGroupId = DEFAULT_GROUP) => {
    if (!tableId) return;

    let _tableListDataMap = getCrudDataStore().listDataMap.get(tableId);

    if (!_tableListDataMap) {
      _tableListDataMap = new ObservableMap();
      getCrudDataStore().listDataMap.set(tableId, _tableListDataMap);
    }

    _tableListDataMap.set(groupId, observable.array(data));
  }
);
