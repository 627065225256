import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export type TUpdateItemActionOption<T extends IBaseCrudItem = IBaseCrudItem> = {
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<T>;
};

export const updateItemAction = action(
  'updateItemAction',
  <T extends IBaseCrudItem = any>(
    tableId: string | undefined,
    itemId: string | number | undefined,
    data: T | undefined,
    options?: TUpdateItemActionOption<T>
  ) => {
    return {
      ...options,
      tableId,
      itemId,
      data,
      groupId: options?.groupId ?? DEFAULT_GROUP,
      functionGroupId: options?.functionGroupId ?? DEFAULT_GROUP
    };
  }
);
