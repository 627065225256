import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export type TDeleteMultiItemsActionOption = {
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<Array<IBaseCrudItem['id']>>;
};

export const deleteMultiItemsAction = action(
  'deleteMultiItemsAction',
  (tableId: string | undefined, itemIds: string[], options?: TDeleteMultiItemsActionOption) => {
    return {
      tableId,
      itemIds,
      groupId: options?.groupId ?? DEFAULT_GROUP,
      functionGroupId: options?.functionGroupId ?? DEFAULT_GROUP,
      callback: options?.callback
    };
  }
);
