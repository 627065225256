import { observable, ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export const setItemListDataAction = mutatorAction(
  'setItemListDataAction',
  (
    tableId: string | undefined,
    itemId: string | number | undefined,
    data: IBaseCrudItem | undefined,
    groupId: TDataGroupId = DEFAULT_GROUP
  ) => {
    if (!tableId || !itemId) return;

    // ** init current table group if not exist
    let _tableListDataMap = getCrudDataStore().listDataMap.get(tableId);

    if (!_tableListDataMap) {
      _tableListDataMap = new ObservableMap();
      getCrudDataStore().listDataMap.set(tableId, _tableListDataMap);
    }

    // ** init current table group data if not exist
    let _groupListDataArray = _tableListDataMap.get(groupId);

    if (!_groupListDataArray) {
      _groupListDataArray = observable.array([]);
      _tableListDataMap.set(groupId, _groupListDataArray);
    }

    // ** find and replace item data
    const _itemIndex = _groupListDataArray.findIndex(item => item.id === itemId);
    if (_itemIndex < 0) return;

    if (!data) {
      _groupListDataArray.splice(_itemIndex, 1);
    } else {
      _groupListDataArray[_itemIndex] = data;
    }
  }
);
