import { computedFn } from 'mobx-utils';

import { TDataGroupId, TTableId } from '../store/crud-data.type';
import { pageSizeSelector } from './page-size.selector';
import { totalItemsSelector } from './total-items.selector';

export const totalPagesSelector = computedFn((tableId: TTableId | undefined, groupId: TDataGroupId | undefined) => {
  if (!tableId) return 0;

  const pageSize = pageSizeSelector(tableId, groupId);
  if (!pageSize) return 0;

  return Math.ceil(totalItemsSelector(tableId, groupId) / pageSize);
});
