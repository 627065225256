import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export const setSingleItemAction = mutatorAction(
  'setItemAction',
  (tableId: string | undefined, itemId: string | number | undefined, item: IBaseCrudItem | undefined) => {
    if (!tableId || !itemId) return;

    let _tableSingleItemMap = getCrudDataStore().singleItemMap.get(tableId);

    if (!_tableSingleItemMap) {
      _tableSingleItemMap = new ObservableMap();
      getCrudDataStore().singleItemMap.set(tableId, _tableSingleItemMap);
    }

    if (!item) {
      _tableSingleItemMap.delete(itemId);
    } else {
      _tableSingleItemMap.set(String(itemId), { ...item, id: itemId });
    }
  }
);
