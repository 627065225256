import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TFetchItemFunction } from '../type/fetch-detail-function.type';

export const fetchItemFunctionSelector = <T extends IBaseCrudItem = IBaseCrudItem>(
  tableId: string | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TFetchItemFunction<T> | undefined => {
  return ((tableId &&
    (getCrudDataStore().fetchItemFunctionMap.get(tableId)?.get(functionGroupId) ||
      getCrudDataStore().fetchItemFunctionMap.get(tableId)?.get(DEFAULT_GROUP))) ||
    undefined) as TFetchItemFunction<T> | undefined;
};
