import { observable, ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { allItemsSelector } from '../selector/all-items.selector';
import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';

export const addListDataAction = mutatorAction(
  'addListDataAction',
  <T extends { id?: string | number }>(
    tableId: TTableId | undefined,
    data: Array<T>,
    options?: {
      addToFirst?: boolean;
      groupId?: TDataGroupId;
    }
  ) => {
    if (!tableId) return;

    const { addToFirst = false, groupId = DEFAULT_GROUP } = options || {};

    // ** remove items has same unique ID
    const _allData = allItemsSelector<T>(tableId, groupId);
    const _removeDuplicateData = data.filter(item => !_allData.some((compareItem: any) => compareItem.id === item.id));

    // ** init current table group if not exist
    let _tableListDataMap = getCrudDataStore().listDataMap.get(tableId);

    if (!_tableListDataMap) {
      _tableListDataMap = new ObservableMap();
      getCrudDataStore().listDataMap.set(tableId, _tableListDataMap);
    }

    // ** init current table group data if not exist
    let _groupListDataArray = _tableListDataMap.get(groupId);

    if (!_groupListDataArray) {
      _groupListDataArray = observable.array([]);
      _tableListDataMap.set(groupId, _groupListDataArray);
    }

    // ** add data
    if (addToFirst) {
      _groupListDataArray.unshift(..._removeDuplicateData);
    } else {
      _groupListDataArray.push(..._removeDuplicateData);
    }
  }
);
