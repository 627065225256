import { action } from 'satcheljs';

import { DEFAULT_GROUP, TTableId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TGoToPageActionOption } from './go-to-page.action';

export const prevPageAction = action(
  'prevPageAction',
  <T extends IBaseCrudItem = any>(tableId: TTableId | undefined, options?: TGoToPageActionOption<T>) => {
    return {
      tableId,
      groupId: options?.groupId ?? DEFAULT_GROUP,
      searchFunctionGroupId: options?.searchFunctionGroupId ?? DEFAULT_GROUP,
      callback: options?.callback
    };
  }
);
