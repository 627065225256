import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { TAddNewFunction } from '../type/add-new-function.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export const setAddNewFunctionAction = mutatorAction(
  'setAddNewFunctionAction',
  <T extends IBaseCrudItem = any>(
    tableId: string | undefined,
    addNewFunction: TAddNewFunction<T>,
    functionGroupId: TFunctionGroupId = DEFAULT_GROUP
  ) => {
    if (!tableId) return;

    let _tableAddNewFunctionMap = getCrudDataStore().addNewFunctionMap.get(tableId);

    if (!_tableAddNewFunctionMap) {
      _tableAddNewFunctionMap = new ObservableMap<string, TAddNewFunction>();

      getCrudDataStore().addNewFunctionMap.set(tableId, _tableAddNewFunctionMap);
    }

    _tableAddNewFunctionMap?.set(functionGroupId, addNewFunction);
  }
);
