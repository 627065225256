import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { ICrudDataStore } from './crud-data.type';

const initStore: ICrudDataStore = {
  listDataMap: new ObservableMap(),
  searchParamsMap: new ObservableMap(),
  searchFunctionMap: new ObservableMap(),
  paginationMetaMap: new ObservableMap(),
  fetchItemFunctionMap: new ObservableMap(),
  singleItemMap: new ObservableMap(),
  addNewFunctionMap: new ObservableMap(),
  updateFunctionMap: new ObservableMap(),
  deleteFunctionMap: new ObservableMap(),
  defaultSearchParamsMap: new ObservableMap(),
  listChunkAddMultiItems: new ObservableMap(),
  addNewMultiFunctionMap: new ObservableMap(),
  addChunkTimeout: new ObservableMap(),
  deleteMultiFunctionMap: new ObservableMap()
};

export const getCrudDataStore = createStore('crud-data-store', initStore);
