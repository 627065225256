import { IBaseCrudItem } from './base-crud-item.type';

export type TSearchResponse<T extends IBaseCrudItem = IBaseCrudItem> = {
  data?: T[];
  remain?: number;
  next?: string;
};

// For outdated but still be implemented APIs
export type TOutdatedSearchResponse<T> = {
  _embedded?: T;
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
};
