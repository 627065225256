import { orchestrator } from 'satcheljs';

import { configSearchAction } from '../action/config-search.action';
import { setListDataAction } from '../mutator-action/set-list-data.action';
import { setPaginationMetaAction } from '../mutator-action/set-pagination-meta.action';
import { setSearchFunctionAction } from '../mutator-action/set-search-function.action';
import { setSearchParamsAction } from '../mutator-action/set-search-params.action';
import { getCrudDataStore } from '../store/crud-data.store';

orchestrator(configSearchAction, actionMessage => {
  const {
    tableId,
    search,
    defaultSearchParams = {},
    replaceExists,
    seekingKeyName,
    seekingValueName,
    groupId,
    functionGroupId
  } = actionMessage;

  if (!tableId) return;

  const searchFunction = getCrudDataStore().searchFunctionMap.get(tableId)?.get(functionGroupId);

  if (replaceExists || !searchFunction) {
    setSearchFunctionAction(tableId, search, functionGroupId);

    setSearchParamsAction(tableId, defaultSearchParams, {
      groupId,
      replace: true
    });

    setPaginationMetaAction(
      tableId,
      {
        canLoadMore: true,
        currentPage: 1,
        seekingKeyName,
        seekingValueName
      },
      {
        groupId,
        replace: true
      }
    );

    setListDataAction(tableId, [], groupId);
  }
});
