import { IObservableArray, ObservableMap } from 'mobx';

import { TAddNewFunction } from '../type/add-new-function.type';
import { TAddNewMultiFunction } from '../type/add-new-multi-function.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';
import { TDeleteItemFunction } from '../type/delete-item-function.type';
import { TDeleteMultiItemsFunction } from '../type/delete-multi-items-function.type';
import { TFetchItemFunction } from '../type/fetch-detail-function.type';
import { IPaginationMeta } from '../type/pagination-meta.type';
import { TSearchFunction } from '../type/search-function.type';
import { TSearchParams } from '../type/search-params.type';
import { TUpdateItemFunction } from '../type/update-item-function.type';

export const DEFAULT_GROUP = 'default';

export type TTableId = string;

/** use for grouping data */
export type TDataGroupId = string;

/** use for grouping function */
export type TFunctionGroupId = string;

export type TItemId = IBaseCrudItem['id'];

/**
 * The `ICrudDataStore` interface defines the structure of a
 * data store that is used for caching
 * and managing table data, pagination metadata, search parameters,
 * search functions, and detail item data.
 */
export interface ICrudDataStore {
  /**
   * The `listDataMap` property in the `ICrudDataStore` interface is used to cache table data. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to an `IObservableArray`. This allows for efficient storage and retrieval of table data, with the ability to group data by different criteria if needed.
   */
  listDataMap: ObservableMap<TTableId, ObservableMap<TDataGroupId, IObservableArray>>;

  /**
   * The `paginationMetaMap` property in the `ICrudDataStore` interface is used to cache pagination metadata for each table and group. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to an `IPaginationMeta` object.
   */
  paginationMetaMap: ObservableMap<TTableId, ObservableMap<TDataGroupId, IPaginationMeta>>;

  /**
   * The `searchParamsMap` property in the `ICrudDataStore` interface is used to cache table search parameters. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to a `TSearchParams` object. */
  searchParamsMap: ObservableMap<TTableId, ObservableMap<TDataGroupId, TSearchParams>>;

  /**
   * default init search params use for first search, if no declare group search params
   */
  defaultSearchParamsMap: ObservableMap<TTableId, TSearchParams>;

  /**
   * The `searchFunctionMap` property in the `ICrudDataStore` interface is used to store the search function for each table and group. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to a `TSearchFunction`.
   */
  searchFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TSearchFunction>>;

  /**
   * The `addNewFunctionMap` property in the `ICrudDataStore` interface is used to store the "add new" function for each table and group.*/
  addNewFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TAddNewFunction<any>>>;

  /**
   * The `updateFunctionMap` property in the `ICrudDataStore` interface is used to store the "update item" function for each table and group. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to a `TUpdateItemFunction`.
   */
  updateFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TUpdateItemFunction<any>>>;

  /**
   * The `fetchItemFunctionMap` property in the `ICrudDataStore` interface is used to store the "fetch detail" function for each table and group. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to a `TFetchItemFunction`.
   */
  fetchItemFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TFetchItemFunction>>;

  /**
   * The `deleteFunctionMap` property in the `ICrudDataStore` interface is used to store the "delete item" function for each table and group. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of a group data in the table) to a `TDeleteItemFunction`. This allows for efficient storage and retrieval of the delete item function, with the ability to associate the function with specific tables and groups.
   */
  deleteFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TDeleteItemFunction>>;

  /** Allow delete multiple item ids */
  deleteMultiFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TDeleteMultiItemsFunction>>;

  /**
   * The `singleItemMap` property in the `ICrudDataStore` interface is used to cache the detail item data for each table and item. It is a nested `ObservableMap` that maps a `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TItemId` (unique ID of an item in the table) to a `Record<string, unknown>` object. This allows for efficient storage and retrieval of detail item data, with the ability to associate the data with specific tables and items.
   */
  singleItemMap: ObservableMap<TTableId, ObservableMap<TItemId, Record<string, unknown> & IBaseCrudItem>>;

  /* The `listChunkAddMultiItems` property in the `ICrudDataStore` interface is used to cache the data
  for adding multiple items in chunks. It is a nested `ObservableMap` that maps a `TTableId` (unique
  ID of a table) to another `ObservableMap` that maps a string (unique ID of a chunk) to an
  `IObservableArray`. This allows for efficient storage and retrieval of the data for adding
  multiple items in chunks, with the ability to associate the data with specific tables and chunks. */
  listChunkAddMultiItems: ObservableMap<TTableId, ObservableMap<string, IObservableArray>>;

  /* The `addNewMultiFunctionMap` property in the `ICrudDataStore` interface is used to store the "add
  new multiple items" function for each table and group. It is a nested `ObservableMap` that maps a
  `TTableId` (unique ID of a table) to another `ObservableMap` that maps a `TGroupId` (unique ID of
  a group data in the table) to a `TAddNewMultiFunction`. This allows for efficient storage and
  retrieval of the "add new multiple items" function, with the ability to associate the function
  with specific tables and groups. */
  addNewMultiFunctionMap: ObservableMap<TTableId, ObservableMap<TFunctionGroupId, TAddNewMultiFunction<any>>>;

  /* The `addChunkTimeout` property in the `ICrudDataStore` interface is used to store the timeout for
  adding chunks of multiple items. It is a nested `ObservableMap` that maps a `TTableId` (unique ID
  of a table) to another `ObservableMap` that maps a string (unique ID of a chunk) to a
  `ReturnType<typeof setInterval>` object. This allows for efficient storage and retrieval of the timeout for adding
  chunks of multiple items, with the ability to associate the timeout with specific tables and
  chunks. */
  addChunkTimeout: ObservableMap<TTableId, ObservableMap<string, ReturnType<typeof setInterval>>>;
}
