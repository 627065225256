import { sum } from 'lodash';
import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { goToPageAction } from '../action/go-to-page.action';
import { searchAction } from '../action/search.action';
import { setListDataAction } from '../mutator-action/set-list-data.action';
import { setPaginationMetaAction } from '../mutator-action/set-pagination-meta.action';
import { setSearchParamsAction } from '../mutator-action/set-search-params.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { defaultSearchParamsSelector } from '../selector/default-search-params.selector';
import { pageItemsSelector } from '../selector/page-items.selector';
import { paginationMetaSelector } from '../selector/pagination-meta.selector';
import { searchFunctionSelector } from '../selector/search-function.selector';
import { searchParamsSelector } from '../selector/search-params.selector';
import { seekingKeyNameSelector } from '../selector/seeking-key-name.selector';
import { seekingValueNameSelector } from '../selector/seeking-value-name.selector';
import { TSearchParams } from '../type/search-params.type';
import { getTableLoadingId } from '../util/get-table-loading-id.util';

orchestrator(searchAction, async actionMessage => {
  const { tableId, params, callback, groupId, functionGroupId, force, reset } = actionMessage;

  handler: try {
    if (!tableId) throw new Error('tableId missing!');

    const searchFunction = searchFunctionSelector(tableId, functionGroupId);

    if (!searchFunction) throw new Error('searchFunction not found!');

    if (LoadingStore.localLoadingSelector(getTableLoadingId(tableId, groupId))) break handler;

    const prevSearchParams = reset ? (params ? undefined : defaultSearchParamsSelector(tableId)) : searchParamsSelector(tableId, groupId);

    // ** check don't make new search if params not change and has data
    if (!force && !reset) {
      const hasNewParams =
        params &&
        Object.keys(params).some(
          key =>
            (params as any)?.[key] != null && JSON.stringify((prevSearchParams as any)?.[key]) !== JSON.stringify((params as any)?.[key])
        );

      if (!hasNewParams) {
        const paginationMeta = paginationMetaSelector(tableId, groupId);
        const latestPageData = pageItemsSelector(tableId, paginationMeta?.currentPage || 1, groupId);

        if (latestPageData.length > 0) {
          goToPageAction(tableId, paginationMeta?.currentPage || 1, {
            groupId,
            searchFunctionGroupId: functionGroupId
          });
          break handler;
        }
      }
    }

    const seekingKeyName = seekingKeyNameSelector(tableId, groupId);
    const seekingValueName = seekingValueNameSelector(tableId, groupId);

    const searchParams = {
      ...prevSearchParams,
      ...params,
      ...(seekingKeyName ? { [seekingKeyName]: undefined } : undefined)
    } as TSearchParams;

    LoadingStore.updateLocalLoadingAction(getTableLoadingId(tableId, groupId), true);

    const _response = await searchFunction(searchParams);

    setSearchParamsAction(
      tableId,
      {
        ...searchParams,
        ...(seekingKeyName
          ? {
              page: undefined,
              [seekingKeyName]:
                (_response.data?.[_response.data.length - 1] as any)?.[seekingValueName || seekingKeyName] ??
                (_response as any)?.[seekingValueName || seekingKeyName]
            }
          : {})
      },
      {
        groupId,
        replace: reset
      }
    );

    setPaginationMetaAction(
      tableId,
      {
        canLoadMore: Boolean(_response.remain ?? (_response.data?.length ?? 0) >= searchParams.size),
        currentPage: 1,
        totalItems: (_response?.remain ?? 0) > 0 ? sum([_response.data?.length, _response?.remain]) : undefined
      },
      {
        groupId
      }
    );

    setListDataAction(tableId, _response?.data || [], groupId);

    // ** update detail items
    _response?.data?.forEach((item: any) => {
      setSingleItemAction(tableId, item.id, item);
    });

    LoadingStore.updateLocalLoadingAction(getTableLoadingId(tableId, groupId), false);

    callback?.success?.(_response);
  } catch (error) {
    LoadingStore.updateLocalLoadingAction(getTableLoadingId(tableId, groupId), false);

    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
