import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { DEFAULT_GROUP, TDataGroupId, TFunctionGroupId } from '../store/crud-data.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export type TAddNewMultiItemsActionOption<T extends IBaseCrudItem = IBaseCrudItem> = {
  groupId?: TDataGroupId;
  functionGroupId?: TFunctionGroupId;
  callback?: TActionCallback<T[]>;
  chunkId?: string;
  chunkSize?: number;
  delay?: number;
};

export const addNewMultiItemsAction = action(
  'addNewMultiItemsAction',
  <T extends IBaseCrudItem = any>(tableId: string | undefined, data: T[] | undefined, options?: TAddNewMultiItemsActionOption<T>) => {
    return {
      ...options,
      tableId,
      data,
      groupId: options?.groupId ?? DEFAULT_GROUP,
      functionGroupId: options?.functionGroupId ?? DEFAULT_GROUP
    };
  }
);
