import './add-new-item.orchestrator';
import './add-new-multi-item.orchestrator';
import './change-page-size.orchestrator';
import './config-search.orchestrator';
import './delete-item.orchestrator';
import './delete-multi-items.orchestrator';
import './fetch-item.orchestrator';
import './go-to-page.orchestrator';
import './next-page.orchestrator';
import './prev-page.orchestrator';
import './refresh.orchestrator';
import './remove-data.orchestrator';
import './search.orchestrator';
import './update-item.orchestrator';
