import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { TDeleteItemFunction } from '../type/delete-item-function.type';

export const setDeleteFunctionAction = mutatorAction(
  'setDeleteFunctionAction',
  (tableId: string | undefined, deleteFunction: TDeleteItemFunction, functionGroupId: TFunctionGroupId = DEFAULT_GROUP) => {
    if (!tableId) return;

    let _tableDeleteFunctionMap = getCrudDataStore().deleteFunctionMap.get(tableId);

    if (!_tableDeleteFunctionMap) {
      _tableDeleteFunctionMap = new ObservableMap<string, TDeleteItemFunction>();
      getCrudDataStore().deleteFunctionMap.set(tableId, _tableDeleteFunctionMap);
    }

    _tableDeleteFunctionMap?.set(functionGroupId, deleteFunction);
  }
);
