import { orchestrator } from 'satcheljs';

import { getActionMeta, LoadingStore } from '@totopkg/shared-util-common';

import { addNewMultiItemsAction } from '../action/add-new-multi-items.action';
import { addListDataAction } from '../mutator-action/add-list-data.action';
import { setAddChunkTimeoutAction } from '../mutator-action/set-add-chunk-timeout.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { addNewMultiFunctionSelector } from '../selector/add-new-multi-function.selector';

orchestrator(addNewMultiItemsAction, async actionMessage => {
  const { tableId, data, groupId, functionGroupId, callback, chunkId, chunkSize, delay } = actionMessage;

  try {
    if (!tableId) throw new Error('tableId missing!');
    if (!data) throw new Error('data not found!');

    const _addNewMultiFunction = addNewMultiFunctionSelector(tableId, functionGroupId);

    if (!_addNewMultiFunction) throw new Error('addNewFunction not found!');

    const _copyData = [...data];

    const _chunkData = _copyData.splice(0, chunkSize ?? data.length);

    if (!_chunkData.length) {
      return;
    }

    LoadingStore.updateLocalLoadingAction(`${getActionMeta(addNewMultiItemsAction).id}-${chunkId}`, true);

    const _response = await _addNewMultiFunction?.(_chunkData);

    if (_response) {
      _response.forEach((item: any) => {
        setSingleItemAction(tableId, item?.id, item);
      });

      addListDataAction(tableId, _response, {
        addToFirst: true,
        groupId
      });
    }

    callback?.success?.(_response);

    if (_copyData.length > 0) {
      setAddChunkTimeoutAction(
        tableId,
        setTimeout(() => {
          addNewMultiItemsAction(tableId, _copyData, {
            functionGroupId,
            groupId,
            callback,
            chunkId,
            chunkSize,
            delay
          });
        }, delay)
      );
    }
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    LoadingStore.updateLocalLoadingAction(`${getActionMeta(addNewMultiItemsAction).id}-${chunkId}`, false);
    callback?.finally?.();
  }
});
