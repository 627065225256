import { orchestrator } from 'satcheljs';

import { changePageSizeAction } from '../action/change-page-size.action';
import { goToPageAction } from '../action/go-to-page.action';
import { setPaginationMetaAction } from '../mutator-action/set-pagination-meta.action';
import { setSearchParamsAction } from '../mutator-action/set-search-params.action';
import { pageItemsSelector } from '../selector/page-items.selector';
import { pageSizeSelector } from '../selector/page-size.selector';

orchestrator(changePageSizeAction, actionMessage => {
  const { tableId, size, groupId, searchFunctionGroupId, callback } = actionMessage;

  if (!tableId) return callback?.error?.('table id not exist');

  const prevPageSize = pageSizeSelector(tableId, groupId);
  const prevFirstPageSize = pageItemsSelector(tableId, 1, groupId).length;

  setSearchParamsAction(
    tableId,
    {
      size
    },
    {
      groupId
    }
  );
  if (size <= prevPageSize || prevFirstPageSize < prevPageSize) {
    setPaginationMetaAction(
      tableId,
      {
        currentPage: 1
      },
      {
        groupId
      }
    );
    callback?.finally?.();
  } else {
    goToPageAction(tableId, 1, {
      groupId,
      callback,
      searchFunctionGroupId: searchFunctionGroupId
    });
  }
});
