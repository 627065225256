import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { TDeleteMultiItemsFunction } from '../type/delete-multi-items-function.type';

export const setDeleteMultiFunctionAction = mutatorAction(
  'setDeleteMultiFunctionAction',
  (tableId: string | undefined, deleteFunction: TDeleteMultiItemsFunction, functionGroupId: TFunctionGroupId = DEFAULT_GROUP) => {
    if (!tableId) return;

    let _tableDeleteMultiFunctionMap = getCrudDataStore().deleteMultiFunctionMap.get(tableId);

    if (!_tableDeleteMultiFunctionMap) {
      _tableDeleteMultiFunctionMap = new ObservableMap<string, TDeleteMultiItemsFunction>();
      getCrudDataStore().deleteMultiFunctionMap.set(tableId, _tableDeleteMultiFunctionMap);
    }

    _tableDeleteMultiFunctionMap?.set(functionGroupId, deleteFunction);
  }
);
