import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TSearchFunction } from '../type/search-function.type';

export const searchFunctionSelector = <P, T extends { id?: string | number }>(
  tableId: TTableId | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TSearchFunction<P, T> | undefined => {
  return (
    ((tableId &&
      (getCrudDataStore().searchFunctionMap.get(tableId)?.get(functionGroupId) ||
        getCrudDataStore().searchFunctionMap.get(tableId)?.get(DEFAULT_GROUP))) as TSearchFunction<P, T>) || undefined
  );
};
