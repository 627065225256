import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId, TTableId } from '../store/crud-data.type';
import { TAddNewFunction } from '../type/add-new-function.type';
import { IBaseCrudItem } from '../type/base-crud-item.type';

export const addNewFunctionSelector = <T extends IBaseCrudItem>(
  tableId: TTableId | undefined,
  functionGroupId: TFunctionGroupId = DEFAULT_GROUP
): TAddNewFunction<T> | undefined => {
  return (
    (tableId &&
      (getCrudDataStore().addNewFunctionMap.get(tableId)?.get(functionGroupId) ||
        getCrudDataStore().addNewFunctionMap.get(tableId)?.get(DEFAULT_GROUP))) ||
    undefined
  );
};
