import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';
import { IPaginationMeta } from '../type/pagination-meta.type';

export const setPaginationMetaAction = mutatorAction(
  'setPaginationMetaAction',
  (tableId: TTableId | undefined, meta: IPaginationMeta, options?: { replace?: boolean; groupId?: TDataGroupId }) => {
    if (!tableId) return;

    const { groupId = DEFAULT_GROUP, replace = false } = options || {};

    let _tablePaginationMetaMap = getCrudDataStore().paginationMetaMap.get(tableId);

    if (!_tablePaginationMetaMap) {
      _tablePaginationMetaMap = new ObservableMap();
      getCrudDataStore().paginationMetaMap.set(tableId, _tablePaginationMetaMap);
    }

    if (replace) {
      _tablePaginationMetaMap.set(groupId, meta);
    } else {
      const prevMeta = _tablePaginationMetaMap.get(groupId);
      _tablePaginationMetaMap.set(groupId, { ...prevMeta, ...meta });
    }
  }
);
