import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP } from '../store/crud-data.type';

export const setAddChunkTimeoutAction = mutatorAction(
  'setAddChunkTimeoutAction',
  (tableId: string | undefined, timeout: ReturnType<typeof setInterval> | undefined, chunkId: string = DEFAULT_GROUP) => {
    if (!tableId) return;

    let _tableAddChunkTimeout = getCrudDataStore().addChunkTimeout.get(tableId);

    if (!_tableAddChunkTimeout) {
      _tableAddChunkTimeout = new ObservableMap();
      getCrudDataStore().addChunkTimeout.set(tableId, _tableAddChunkTimeout);
    }

    const _prevTimeout = _tableAddChunkTimeout.get(chunkId);
    if (_prevTimeout) clearTimeout(_prevTimeout);

    if (!timeout) {
      _tableAddChunkTimeout.delete(chunkId);
    } else {
      _tableAddChunkTimeout.set(chunkId, timeout);
    }
  }
);
