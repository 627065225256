import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TDataGroupId, TTableId } from '../store/crud-data.type';

export const pageSizeSelector = (tableId: TTableId | undefined, groupId: TDataGroupId = DEFAULT_GROUP) => {
  return (
    (tableId &&
      (getCrudDataStore().searchParamsMap.get(tableId)?.get(groupId)?.size ||
        getCrudDataStore().searchParamsMap.get(tableId)?.get(DEFAULT_GROUP)?.size)) ||
    0
  );
};
