import { orchestrator } from 'satcheljs';

import { addNewItemAction } from '../action/add-new-item.action';
import { addListDataAction } from '../mutator-action/add-list-data.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { addNewFunctionSelector } from '../selector/add-new-function.selector';

orchestrator(addNewItemAction, async actionMessage => {
  const { tableId, data, groupId, functionGroupId, callback } = actionMessage;

  try {
    if (!tableId) throw new Error('tableId missing!');
    if (!data) throw new Error('data not found!');

    const addNewFunction = addNewFunctionSelector(tableId, functionGroupId);

    if (!addNewFunction) throw new Error('addNewFunction not found!');

    const _response = await addNewFunction?.(data);
    setSingleItemAction(tableId, _response?.id, _response);

    if (_response) {
      addListDataAction(tableId, [_response], {
        addToFirst: true,
        groupId
      });
    }

    callback?.success?.(_response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
