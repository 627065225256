import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getCrudDataStore } from '../store/crud-data.store';
import { DEFAULT_GROUP, TFunctionGroupId } from '../store/crud-data.type';
import { TFetchItemFunction } from '../type/fetch-detail-function.type';

export const setFetchItemFunction = mutatorAction(
  'setFetchItemFunction',
  (tableId: string | undefined, fetchFunction: TFetchItemFunction, functionGroupId: TFunctionGroupId = DEFAULT_GROUP) => {
    if (!tableId) return;

    let _tableFetchDetailFunctionMap = getCrudDataStore().fetchItemFunctionMap.get(tableId);

    if (!_tableFetchDetailFunctionMap) {
      _tableFetchDetailFunctionMap = new ObservableMap();

      getCrudDataStore().fetchItemFunctionMap.set(tableId, _tableFetchDetailFunctionMap);
    }

    _tableFetchDetailFunctionMap.set(functionGroupId, fetchFunction);
  }
);
