import { orchestrator } from 'satcheljs';

import { updateItemAction } from '../action/update-item.action';
import { setItemListDataAction } from '../mutator-action/set-item-list-data.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { updateFunctionSelector } from '../selector/update-function.selector';

orchestrator(updateItemAction, async actionMessage => {
  const { tableId, itemId, data, groupId, functionGroupId, callback } = actionMessage;

  try {
    if (!tableId) throw new Error('tableId missing!');
    if (!itemId) throw new Error('itemId missing!');
    // if (!data) throw new Error('no data to update!');

    const updateFunction = updateFunctionSelector(tableId, functionGroupId);
    if (!updateFunction) throw new Error('updateFunction not found!');

    const _response = await updateFunction(itemId, data);

    setItemListDataAction(tableId, itemId, _response, groupId);
    setSingleItemAction(tableId, itemId, _response);
    callback?.success?.(_response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
