import { orchestrator } from 'satcheljs';

import { fetchItemAction } from '../action/fetch-item.action';
import { setSingleItemAction } from '../mutator-action/set-single-item.action';
import { fetchItemFunctionSelector } from '../selector/fetch-item-function.selector';
import { singleItemSelector } from '../selector/single-item.selector';

orchestrator(fetchItemAction, async actionMessage => {
  const { tableId, itemId, force, functionGroupId, callback } = actionMessage;

  try {
    if (!tableId) throw new Error('tableId missing!');
    if (!itemId) throw new Error('itemId missing!');

    const detailItem = singleItemSelector(tableId, itemId);
    if (!force && detailItem) return callback?.success?.(detailItem);

    const fetchFunction = fetchItemFunctionSelector(tableId, functionGroupId);

    if (!fetchFunction) throw new Error('fetch function not found!');

    const _response = await fetchFunction(itemId);
    setSingleItemAction(tableId, _response?.id, _response);
    callback?.success?.(_response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
